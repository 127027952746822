import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, ActionList } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/action-list.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, ActionList: ActionList }}
        code={snippet}
        platform="react"
        gitHubLink="action-list"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>
              Determines the type of button for the action list. Acceptable
              types are:
            </Text>
            <List type="unordered">
              <li>
                <code>primary</code> (default)
              </li>
              <li>
                <code>secondary</code>
              </li>
              <li>
                <code>subtle</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="actions" types={['arrayOf', 'shape']}>
            <Text>
              The items in the action list. Each action has the following shape:
            </Text>
            <List type="unordered">
              <li>
                <code>label</code> (string) - the text describing the action
              </li>
              <li>
                <code>icon</code> (string) - name of a Uniform icon
              </li>
              <li>
                <code>isDestructive</code> (bool) - determines whether the an
                action is destructive so that it can receive special visual
                affordance
              </li>
              <li>
                <code>onClick</code> (func) - responds to the action being
                clicked
              </li>
              <li>
                <code>qaId</code> (string) - a unique string to control this
                action in automated tests
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control the action list button in automated
              tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
